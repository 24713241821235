<template>
  <div class="app-tabs-wrap flex bg-white text-md text-grey-fp-40 shadow-1 rounded-50 w-full md:w-auto">
    <div
      v-for="item of items"
      :key="item.value"
      class="tab px-30 py-10 cursor-pointer rounded-50 whitespace-nowrap w-full md:w-auto text-center md:text-start"
      :class="[item.value === modelValue.value && `active ${activeClassees}`, customTabClasses]"
      @click="handleClick(item)"
    >
      <span>
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeMount, PropType } from 'vue';

  import { IOption } from '@/types';

  export default defineComponent({
    name: 'AppTabs',

    props: {
      modelValue: {
        type: Object,
        required: true
      },

      items: {
        type: Array as PropType<IOption[]>,
        required: true
      },

      activeClassees: {
        type: String,
        default: 'bg-primary'
      },

      customTabClasses: {
        type: String,
        default: ''
      },
    },

    emits: ['update:modelValue', 'change'],

    setup(props, { emit }) {

      function handleClick(value: any) { 
        emit('update:modelValue', value); 
        emit('change', value);
      }

      function preselectValue() {
        if (!Object.keys(props.modelValue).length) {
          emit('update:modelValue', props.items[0]);
        }
      }

      onBeforeMount(() => { preselectValue(); });

      return { handleClick };
    }
  });
</script>

<style lang="scss" scoped>
  .app-tabs-wrap {
    @aplly shadow-1;

    .tab {
      &.active {
        @apply text-white;
      }

      &:hover:not(.active) {
        @apply hover:bg-blue-fp-10 hover:text-grey-fp-70;
      }
    }
  }
</style>
