<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30">Tabs</div>
  <div class="flex flex-col justify-center items-center h-full">
    <AppTabs v-model="activeTab" :items="tabs" class="mb-20" />
    <p class="text-xl">
      <span class="text-grey-fp-60 mr-20">Selected value:</span> 
      <span class="text-dark-cl-20">{{ activeTab.label }}</span> 
    </p> 
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, reactive } from 'vue';

import AppTabs from '@/components/stateless/AppTabs.vue';

import { IOption } from '@/types';

export default defineComponent({
  name: 'Tabs',

  components: { AppTabs },

  setup() {
    const tabs: IOption[] = [
      { value: 'tab1', label: 'Tab 1'},
      { value: 'tab2', label: 'Activities'},
      { value: 'tab3', label: 'Tab 3'}
    ];

    const state = reactive({ activeTab: {}});
    const { activeTab } = toRefs(state);

    return { tabs, activeTab };
  }

});
</script>